:root {
  
    --background-color:  #03051B;
    
    /* --buttons-bg-color: */
    --text-color: #FFFFFF;

   
}

