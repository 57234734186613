.test-shadow {
  margin-top: 5px;
  background: linear-gradient(92.77deg, #761de8 -2.94%, #29bdec 56.14%);
  background-clip: text;
  color: transparent;
  justify-content: center;
  /* font-size: 28px; */
}

.bg-our-key {
  background-color: #03051b;
}

.button-commonBtn {
  background: linear-gradient(93deg, #761de8 -2.94%, #29bdec 56.14%);
  /* width: 100%;
    height: 100%; */
  border-radius: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border: none;
}

.plan-image {
  margin-bottom: -32px;
  margin-top: 0;
  margin-left: 0;
  width: 183px;
  height: 129px;
}

.best-value {
  width: 100%;
  max-width: 115px;
  position: relative;
  top: -30px;
  right: -18px;
}

.plans-bg-center {
  /* background-color: linear-gradient(92.77deg, #761DE8 -2.94%, #29BDEC 56.14%); */
  background: linear-gradient(92.77deg, #761de8 -2.94%, #29bdec 56.14%);
}
.discountImg {
  width: 90px;
  height: 70px;
}
.discountpopular {
  background-color: white;
  font-size: 14px;
  font-weight: 800;

}
.discountBtnBg {
  background: linear-gradient(102.83deg, #69dbff 17.29%, #be96ff 82.68%);
  color: white;
  font-size: 14px;
  font-weight: 800;
}
.mapBg {
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.get-started-button {
  /* max-width: 162px;
    width: 100%; */
  display: flex;
  justify-content: left;
  margin: 2rem 0;
}

@media only screen and (max-width: 780px) {
  .get-started-button {
    max-width: 100% !important;
    width: 100%;
    justify-content: center;
  }

  .margin-10 {
    margin: 10px;
  }
}
.containerBtn {
  position: relative;
}
.bg-rotate::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 33px;
  height: 33px;
  background: linear-gradient(92.77deg, #761de8 -2.94%, #29bdec 56.14%);
  border-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1; /* Ensure it's behind the content */
  transform: rotate(45deg); /* Adjust rotation angle as needed */
  transform-origin: center; /* Rotate around the center */
}
/* ============================================================================================= */

.container-slider {
  width: 784px;
  height: 220px;
  top: 4641px;
  left: 328px;
  gap: 0px;
  border-radius: 10px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  background: linear-gradient(92.77deg, #761de8 -2.94%, #29bdec 56.14%);
  border: 1px solid;
  border-image-source: linear-gradient(
    92.77deg,
    #761de8 -2.94%,
    #29bdec 56.14%
  );
  box-shadow: 0px 15px 50px 0px #5b84c11a;
}
span.arrowBtn {
  font-size: 33px;
}
