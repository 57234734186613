.commonBtn {
    background: linear-gradient(93deg, #761DE8 -2.94%, #29BDEC 56.14%);
    width: 100%;
    height: 100%;
    border-radius: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
   font-size: 18px;
}
.paraCommnFont {
    font-size: 18px;
    color: rgba(220, 220, 220, 1);

}

@media only screen and (max-width: 640px) {
    .paraCommnFont{
        font-size: 12px;
    }
       .commonBtn {
        font-size: 15px;
        padding: 0.5rem 2rem;
    }
}