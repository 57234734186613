.tagName {
  font-family: "Kanit", sans-serif;
  font-weight: 400;
  font-style: normal;
  color: var(--text-color);
  font-size: 32px;
}

.navLogo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--text-color);
}

.navItems {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}
.tabs-container {
  width: 1200px;
  overflow-x: scroll;
}
.tabs-container::-webkit-scrollbar {
  display: none;
}

.tabs-container {
  scrollbar-width: none;
}
.tabs {
  font-size: 24px;
  padding: 2% 4%;
  height: 71px;
  width: 325px;
}
.tabimg {
  width: 50px;

  height: 50px;
}
.tabimg img {
  width: 50px;
}

.genreBtn {
  background: linear-gradient(92.77deg, #761de8 -2.94%, #29bdec 56.14%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.SignOutBtn {
  width: 100%;
  height: 100%;
  border-radius: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  font-size: 18px;
  position: relative;
}
.SignOutBtn::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 44px;
  padding: 1px;
  background: linear-gradient(92.77deg, #761de8 -2.94%, #29bdec 56.14%);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  pointer-events: none;
}
.signAline {
  display: flex;
  align-items: center;
  gap: 17px;
  color: #f3f3f4;
}

.navBg {
  background-color: #ffffff;
  padding: 1rem;
}

.ProfileDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  z-index: 999 !important;
}

.profilrIcon {
  font-size: 34px;
  color: #56519d;
  position: relative;
}
.SettingBox {
  background-color: rgb(86 91 110 / 8%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 10px;
  padding: 1rem;
  border: 0.2px solid;
  position: absolute;
  right: 26px;
      z-index: 1;
}
.ProfileSection {
  padding: 4rem 20% 3rem 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3rem;
}

@media only screen and (max-width: 1024px) {
  .tabs {
    font-size: 20px;
    width: 300px;
  }
  .tabimg {
    max-width: 40px;
    width: 100%;
    height: 40px;
  }
  .tabs-container {
    width: 1000px;
    overflow-x: auto; /* Enable horizontal scrolling */
    /* Prevent tabs from wrapping */
    width: 1140px; /* Adjust to your desired width */
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
  }

  .signAline {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
      .SignOutBtn{
    padding: 0.5rem;
  }
      .SettingBox {
        right: auto;
        /* left: 69px; */
        margin-left: 1.5rem;
    }

}
@media only screen and (max-width: 1006px) {
  .ProfileSection {
    padding: 0 7% 3rem 7%;
  }
    .SignOutBtn{
    padding: 0.5rem;
  }
}



@media only screen and (max-width: 640px) {
  .tabs {
    font-size: 19px;
    width: 300px;
  }
  .SignOutBtn{
    padding: 0.5rem;
  }
}

@media only screen and (max-width: 380px) {
  .tabs {
    font-size: 16px;
    max-width: 229px;
    height: 48px;
    width: 300px;
  }
}
