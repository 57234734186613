.dragNdrop-box {
    display: flex;
    justify-content: space-around;
}

/* .ansCard {
    height: 230px;
    max-width: 160px;
    width: 100%;
    border: 0.57px solid transparent;
    border-radius: 5.67px;
    border-image-source: linear-gradient(92.77deg, #761DE8 -2.94%, #29BDEC 56.14%);
    border-image-slice: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    background: linear-gradient(92.77deg, rgba(118, 29, 232, 0.3) -2.94%, rgba(41, 189, 236, 0.3) 56.14%) !important;
    
} */
.ansCard {
    position: relative;

    height: 230px;
    max-width: 160px;
    width: 100%;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    transition: transform 0.3s ease-in-out;
    /* background: linear-gradient(92.77deg, rgba(118, 29, 232, 0.3) -2.94%, rgba(41, 189, 236, 0.3) 56.14%) !important; */

}

.ansCard:hover {
    transform: scale(1.3);
    z-index: 1;
}

.ansCard::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 6px;
    padding: 1px;
    background: linear-gradient(92.77deg, #761DE8 -2.94%, #29BDEC 56.14%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    pointer-events: none;
}

.innerAnsBox {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    max-width: 833px;
    width: 100%;
    gap: 10px;
}


.box1 {
    display: flex;
    /* height: 230px; */
    max-width: 829x;
    width: 100%;
    justify-content: space-between;

}

.box2 {
    display: flex;
}

.QueBox {
    width: 32%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.AnsBox {
    width: 64%;
    height: 480px;

}

/* .QuesCard {
    position: relative;
    width: 100%;
    max-width: 424px;
    min-height: 480px;
    height: 100%;
    border: 1px dotted;
    border-image-source: linear-gradient(92.77deg, #761DE8 -2.94%, #29BDEC 56.14%);
    border-image-slice: 1;
    /* Ensures the gradient covers the entire border */
/* display: flex;
    align-items: center;
    justify-content: center;
    
} */
.QuesCard {
    /* position: relative; */
    width: 100%;
    max-width: 424px;
    min-height: 480px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #fff;
    border-radius: 10px;
    flex-direction: column;
}

/* .QuesCard::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px dotted #fff;
    border-image-source: linear-gradient(92.77deg, #761DE8 -2.94%, #29BDEC 56.14%);
    border-image-slice: 1;
} */

/* You can adjust the size and positioning of the before and after elements as needed */

p.questext {
    font-family: "Poppins", sans-serif;
    font-size: 26px;
    font-weight: 500;
    line-height: 39.01px;
    letter-spacing: 0.09287412464618683px;
    text-align: center;
    padding: 0 3rem;
    color: #DCDCDC;

}

.pageIndicater {
    position: absolute;

    top: 0;
    right: 0;
    padding: 1rem 2rem;
    font-size: 28px;
    font-family: "Inter", sans-serif;
    background: linear-gradient(102.83deg, #69DBFF 17.29%, #BE96FF 82.68%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

p.ansText {
    font-size: 9.6px;
    font-family: "Inter", sans-serif;
    font-weight: 300;
    line-height: 11px;
    text-align: center;
    padding: 0 0.8rem;
}

.ansBoxImg {
    max-width: 148.65px;
    width: 100%;
    height: 141.28px;
}

.btnPage {
    /* background: linear-gradient(99.26deg, #AE5DFF 8.42%, #7F90FF 50.53%, #A891F5 89.39%); */
    background-color: #FFFFFF0F;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: linear-gradient(99.26deg, #AE5DFF 8.42%, #7F90FF 50.53%, #A891F5 89.39%) !important;

}

#activeButton {
    background: linear-gradient(99.26deg, #AE5DFF 8.42%, #7F90FF 50.53%, #A891F5 89.39%) !important;
}

.arrow {
    font-size: 32px;
    font-weight: bolder;

}

.innerQueBox {
    max-width: 408px;
    width: 100%;
}

.worldexplorerHd {
    font-size: 20px;
    margin: 0 auto;
}

.innerTabBox {
    margin: auto;
}



.all-dustbins-container {
    /* display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-gap: 2rem; */
    display: flex;
    grid-template-columns: auto auto auto auto auto;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: center;
}

.all-dustbin {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid green;
    border-radius: 10px;
    padding: 1rem;
    max-width: 394px;
    width: 100%;
}

.mainDNDContainer {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}

.droppedImg {
    width: 100%;
 
   height: 340px;
    overflow: hidden;
    position: relative;
   
}
.imgLastDropped {
    width: 100%;
  height: 100%; 
  object-fit: cover;
  position: absolute; 
  top: 0; 
  left: 0;
}

.droppedItem {
    display: flex;
    flex-direction: column;
    padding: 1rem 0.5rem;
    width: 100%;
    height: 100%;
}

.text-dropped {

    display: flex;
    flex-direction: column;
    gap: 5px
}

.dropWord {
    background: linear-gradient(92.77deg, #761DE8 -2.94%, #29BDEC 56.14%);
    background-clip: text;
    color: transparent;
    font-size: 26px;    padding-bottom: 8px;
}

.dropMeaning {
    text-align: left;
    font-size: 11px;
}

.Synonyms {
    text-align: left;
    font-size: 11px;
    color: #1AFF5A;

}

.Antonyms {
    text-align: left;
    font-size: 11px;
    color: #FF00B8;

}

@keyframes shake {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-5px);
    }

    50% {
        transform: translateX(5px);
    }

    75% {
        transform: translateX(-5px);
    }

    100% {
        transform: translateX(0);
    }
}
@media only screen and (max-width: 1182px) {
    .dragNdrop-box {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .QueBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }


    .AnsBox {
        width: 100%;
        height: 520px;
        overflow-x: scroll;
        overflow-y: hidden;
        padding: 1.5rem 0.5rem;
        display: flex;
        justify-content: center;
    }
    .AnsBox::-webkit-scrollbar {
        height: 0px;
   }
 
}

@media only screen and (max-width: 780px) {
    
    .dragNdrop-box {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .QueBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }


    .AnsBox {
        width: 100%;
        height: 480px;
        overflow-x: scroll;
        overflow-y: hidden;
        padding: 1.5rem 0.5rem;
    }

    .AnsBox::-webkit-scrollbar {
        height: 15px;
   
    
    }

    .AnsBox::-webkit-scrollbar-thumb {
        background-color: hsl(263, 68%, 58%);
        border-radius: 10px;
    }

    .AnsBox::-webkit-scrollbar-track {
        background-color: lightgray;
       
    }


    .box1 {
        display: flex;
        height: 230px;

        width: 833px;
        justify-content: space-between;


    }



    .QuesCard {
        height: 417px;
    }

    .dragNdrop-box {
        padding: 0rem;
    }

    .ansCard {
        max-width: 150px;
    }

    .p.ansText {
        padding: 0 0.5px;
        font-size: 9px;
    }

    .worldexplorerHd {
        font-size: 18px;
    }

    .mainQueCard {
        padding: 1rem;
    }
    .droppedImg {
    height: 300px;
    }
}

@media only screen and (max-width: 380px) {
    .QuesCard {

        width: 335px;
        height: 417px;
    }

    .worldexplorerHd {
        font-size: 12px;
    }

    .mainQueCard {
        padding: 1rem;

    }
}