.tb-text {
    font-size: 50px;
    font-weight: 900;
   
    text-align: center;
    padding: 1rem 0;
}

.table.box {
    max-width: 1200px;
   margin: 1rem;
    width: 100%;
    position: relative;
    border-radius: 10px;
}

.table.box::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px;
    padding: 1px;
    background: linear-gradient(99.26deg, #AE5DFF 8.42%, #7F90FF 50.53%, #A891F5 89.39%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    pointer-events: none;
}

.tableTextBox {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
   
} 

.tableTextBox::before{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px; /* Adjust this value to change the thickness of the border */
    background: linear-gradient(99.26deg, #AE5DFF 8.42%, #7F90FF 50.53%, #A891F5 89.39%);
    pointer-events: none;
}
.lastIndex{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
 border-radius: 0 0 10px 10px;
} 


.bgTrans{
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(0, 0, 0, 0.018) 97.88%);

}
.scoreText{
    background: linear-gradient(99.26deg, #AE5DFF 8.42%, #7F90FF 50.53%, #A891F5 89.39%);
    background-clip: text;
    color: transparent;


}

@media only screen and (max-width: 1024px) {
    .tb-text {
        font-size: 40px;
        font-weight: 700;
      padding: 1rem 0.5;
    }
}

@media only screen and (max-width: 640px) {
    .tb-text {
        font-size: 23px;
        font-weight: 600;

    }
}

@media only screen and (max-width: 380px) {
    .tb-text {
        font-size: 20px;
        font-weight: 500;
        
        text-align: center;
        
    }
}