*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html,body{
    width: 100%;
    height: 100%;
}

.Main_Footer_div{
    width: 100%;
    height: 30vh;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    /* background-color: aqua; */
}

.Footer_div_line{
    width: 100%;
    padding: 2px;
    /* background-color: black; */
    background-color: rgb(71 85 105 / var(--tw-border-opacity));
}

.Footer_header_div{
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1rem;
}

.Footer_header_h3{
    color: white;
    font-size: 22px;
    margin-left: 20px;
}

.Footer_link_div{
    
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 20px;
}



.Footer_link_div_button{
    color: white;
    border: none;
    outline: none;
    font-size: 16px;
}


/* .Footer_image_div{
    
}
.Footer_image_div>img{
    width: 100%;
    height: 100%;
} */

@media only screen and (max-width: 488px) {
.Footer_link_div{
    flex-direction: column;
    padding-top: 1rem;
}
}