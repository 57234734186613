.colorBlue {
  background: linear-gradient(92.77deg, #761DE8 -2.94%, #29BDEC 56.14%);
  background-clip: text;
  color: transparent;
}

.colorWhite {
  background-image: #fff;
}



@media only screen and (max-width: 1024px) {}

@media only screen and (max-width: 640px) {}

@media only screen and (max-width: 380px) {
  .hdFont {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  }
}